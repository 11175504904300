import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';
// eslint-disable-next-line import/order
import { uploadPhoto } from './generics';

const PATIENTS_PATH = 'patients/';

export const getProfile = async (token) => {
  const { data } = await axios.get(`${API_URL}${PATIENTS_PATH}`, {
    headers: headers(token),
  });

  return data;
};

export const getStatus = async (token) => {
  const { data } = await axios.get(`${API_URL}${PATIENTS_PATH}status/`, {
    headers: headers(token),
  });
  return data;
};

export const update = async (token, body, file = null) => {
  let uploadResponse;
  if (file) {
    uploadResponse = await uploadPhoto(token, file);
    body['photoURL'] = uploadResponse.url;
  }

  const { data } = await axios.patch(`${API_URL}${PATIENTS_PATH}`, body, {
    headers: headers(token),
  });
  return data;
};

export const updateTags = async (token, id, body) => {
  const { data } = await axios.patch(`${API_URL}${PATIENTS_PATH}${id}/tags/`, body, {
    headers: headers(token),
  });
  return data;
};

export const updateBillingAgreement = async (token, id, body) => {
  const { data } = await axios.put(`${API_URL}${PATIENTS_PATH}${id}/billing-agreement/`, body, {
    headers: headers(token),
  });
  return data;
};

export const getPsychologistsByTags = async (token) => {
  const response = await axios.get(`${API_URL}${PATIENTS_PATH}psychologists/`, {
    headers: headers(token),
  });
  return response.data;
};
