import { CheckCircleIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

export default function SidebarStepsDesktop({
  profileCompleted,
  hasTags,
  hasPsychologist,
  hasContract,
  hasPayment,
}) {
  const statuses = [
    { key: 'profileCompleted', name: 'Completar Perfil', completed: profileCompleted },
    { key: 'hasTags', name: 'Selecciona tus Padecimientos', completed: hasTags },
    { key: 'hasPsychologist', name: 'Elige a tu Dover', completed: hasPsychologist },
    { key: 'hasContract', name: 'Acepta Términos y Condiciones', completed: hasContract },
    { key: 'hasPayment', name: 'Paga', completed: hasPayment },
  ];

  // Calcular el estado de cada paso
  const steps = statuses.map((step, index) => {
    const isComplete =
      statuses.slice(0, index).every((prevStep) => prevStep.completed) && step.completed;
    const isCurrent =
      statuses.slice(0, index).every((prevStep) => prevStep.completed) && !step.completed;

    return {
      id: `0${index + 1}`,
      name: step.name,
      href: '#',
      status: isComplete ? 'complete' : isCurrent ? 'current' : 'upcoming',
    };
  });

  return (
    <div className="px-4 py-12 sm:px-6 lg:px-8">
      <nav className="grid justify-center" aria-label="Progress">
        <ol className="space-y-6">
          {steps.map((step) => (
            <li key={step.id}>
              {step.status === 'complete' ? (
                <a href={step.href} className="group">
                  <span className="flex items-start">
                    <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                      <CheckCircleIcon
                        className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </a>
              ) : step.status === 'current' ? (
                <a href={step.href} className="flex items-start" aria-current="step">
                  <span
                    className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                    aria-hidden="true"
                  >
                    <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                    <span className="relative block h-2 w-2 rounded-full bg-indigo-600" />
                  </span>
                  <span className="ml-3 text-sm font-medium text-indigo-600">{step.name}</span>
                </a>
              ) : (
                <a href={step.href} className="group">
                  <div className="flex items-start">
                    <div
                      className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                      aria-hidden="true"
                    >
                      <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                    </div>
                    <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </p>
                  </div>
                </a>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

SidebarStepsDesktop.propTypes = {
  profileCompleted: PropTypes.bool.isRequired,
  hasTags: PropTypes.bool.isRequired,
  hasPsychologist: PropTypes.bool.isRequired,
  hasContract: PropTypes.bool.isRequired,
  hasPayment: PropTypes.bool.isRequired,
};

SidebarStepsDesktop.defaultProps = {
  profileCompleted: false,
  hasTags: false,
  hasPsychologist: false,
  hasContract: false,
  hasPayment: false,
};
