import Button from 'components/Button';
import Content from 'components/Content';
import Loader from 'components/Loader';
import Title from 'components/Title';
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { create as createAccompaniment } from 'services/accompaniment';
import { getStatus as getUserStatus } from 'services/patient';
import { authState, getTokenSelector } from 'state/auth';
import { requestState } from 'state/request';
import { PSYCHOLOGIST_DEGREES } from 'utils/constants';

export default function Pay() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const [auth, setAuth] = useRecoilState(authState);
  const [request, setRequest] = useRecoilState(requestState);

  // RRD
  const navigate = useNavigate();

  const onSuccessCreateAccompanimentMutation = async () => {
    await userStatusQuery.refetch();
    setRequest({
      periodicityOption: null,
      contractCheck: null,
      contractID: null,
    });
    navigate('/app');
  };

  const createAccompanimentMutation = useMutation((data) => createAccompaniment(token, data), {
    onSuccess: onSuccessCreateAccompanimentMutation,
    onError: (data) => {
      const errors = data.response?.data;
      const message = errors?.detail || errors?.accompaniment || 'Algo salio mal.';
      toast.error(message);
    },
  });

  // Mutation Actions
  const onSuccessPatientStatusRequest = (user) => {
    setAuth({
      token: auth.token,
      user,
    });
  };

  // RQ
  const userStatusQuery = useQuery(['userStatus', auth?.token], () => getUserStatus(auth?.token), {
    enabled: false,
    onSuccess: onSuccessPatientStatusRequest,
  });

  const onSubmit = () => {
    const { psychologist, contractID, availability, paymentMethod } = request;

    let payment_method = paymentMethod;

    createAccompanimentMutation.mutate({
      psychologist: psychologist.id,
      availability: availability.id,
      contract: contractID,
      payment_method,
      // cvv: paymentData.cvv,
    });
  };

  return (
    <>
      <Title className="mt-4 text-title">Confirmar pago</Title>
      <Content className="mt-2 text-content">
        <div>
          <br />
          <p className="leading-5 text-gray-500">
            Confirmación de pago por la cantidad de:
            <span className="leading-6 text-gray-800 font-bold">
              {/* TODO: make dynamic */} $
              {request?.psychologist?.degree
                ? PSYCHOLOGIST_DEGREES[String(request?.psychologist?.degree)].sessionCost
                : ''}{' '}
              USD
            </span>
            <span> / Sesión</span>
          </p>
          <br />
          <p className="leading-5 text-gray-500">
            Al dar click usted esta de acuerdo en que Mindove hará un cobró recurrente después de
            cada sesión por la cantidad antes mencionada.
          </p>
        </div>
      </Content>
      <br />
      <br />
      <div className="grid grid-cols-3 gap-4">
        <div className="cols-span-1">
          <Button
            onClick={() => navigate(-1)}
            disabled={createAccompanimentMutation.isLoading}
            className="btn-tertiary py-4"
          >
            Regresar
          </Button>
        </div>
        <div className="cols-span-1">
          <Button
            onClick={onSubmit}
            disabled={createAccompanimentMutation.isLoading}
            className="btn-primary  py-4"
          >
            Pagar
          </Button>
        </div>
      </div>
      {createAccompanimentMutation.isLoading ? (
        <div className="flex justify-center items-center">
          <Loader className="h-10 w-10 text-secondary" />
        </div>
      ) : null}
    </>
  );
}
