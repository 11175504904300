import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import BackButton from 'components/BackButton';
import Button from 'components/Button';
import Content from 'components/Content';
import Title from 'components/Title';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { updateBillingAgreement } from 'services/patient';
import { capturePayment, createBillingAgreement, executeBillingAgreement } from 'services/payments';
import { getTokenSelector, getUserSelector } from 'state/auth';
import { requestState } from 'state/request';

const Pay = () => {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const user = useRecoilValue(getUserSelector);
  const [request, setRequest] = useRecoilState(requestState);

  // RRD
  const navigate = useNavigate();

  const handleApprove = async (data) => {
    const response = await executeBillingAgreement(token, data.billingToken);
    // Guarda el billingToken en tu backend
    const billingAgreementResponse = await updateBillingAgreement(token, user?.id, {
      paypal_billing_agreement_id: response.id,
    });
    console.log('🚀 ~ billingAgreementResponse:', billingAgreementResponse);

    const newRequestState = {
      hasPaymentMethod: true,
    };
    console.log('🚀 ~ handleApprove ~ newRequestState:', newRequestState);
    setRequest({
      ...request,
      ...newRequestState,
    });
    navigate('../pay');
  };

  return (
    <>
      <div className="col-span-1 md:col-start-2 px-4 w-20 mb-4">
        <BackButton onClick={() => navigate(-1)} />
      </div>
      <div className="sm:col-span-1 md:col-span-6 md:col-start-2 p-4">
        <div>
          <Title className="text-title">Método de pago</Title>
          <Content className="text-gray-500 my-4">
            En Mindove, tu bienestar es nuestra prioridad. <br /> <br />
            Para continuar, deberás añadir un método de pago. Tus datos bancarios estarán protegidos
            por <strong>PayPal</strong>, una plataforma confiable y segura.
            <br />
            <br />
            Usaremos esta tarjeta para gestionar de forma automática los pagos de tus sesiones, para
            que puedas enfocarte en lo más importante: tu progreso emocional y bienestar.
          </Content>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <PayPalScriptProvider
            options={{
              'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
              vault: true,
              intent: 'tokenize',
            }}
          >
            <PayPalButtons
              fundingSource="paypal"
              style={{ layout: 'vertical' }}
              createBillingAgreement={async () => {
                const response = await createBillingAgreement(token);
                console.log('🚀 ~ createBillingAgreement={ ~ response:', response);
                return response.token_id;
              }}
              onApprove={handleApprove}
            />
          </PayPalScriptProvider>
        </div>

        <Button
          onClick={async () => {
            const response = await capturePayment(token);
            console.log('🚀 ~ onClick={ ~ response:', response);
          }}
          className="btn-primary"
        >
          Pay
        </Button>
      </div>
    </>
  );
};

export default Pay;

// 1
// {
//   "orderID": "EC-0FY799949X166761T",
//   "payerID": "E8FLJ59GQS4YU",
//   "paymentID": null,
//   "billingToken": "BA-1AU75411RN5758911",
//   "facilitatorAccessToken": "A21AALuUs3ai3vMZkzBuljXNPagD_mWM-l6mUxXbnYaAIIWuxY4iZl1mgfx0NNffw1u1Ozg9gty3RBVEoJA-ryuztjoa3C41w",
//   "paymentSource": "paypal"
// }

// 2

// {
//   "id": "B-7EV02238AC384892A",
//   "state": "ACTIVE",
//   "description": "Tokenización de método de pago para sesiones del acompañamiento online",
//   "merchant": {
//       "payee_info": {
//           "email": "sb-sn47w531393862@business.example.com"
//       }
//   },
//   "payer": {
//       "payer_info": {
//           "email": "96.davidcm@gmail.com",
//           "first_name": "John",
//           "last_name": "Doe",
//           "payer_id": "E8FLJ59GQS4YU",
//           "tenant": "PAYPAL"
//       }
//   },
//   "plan": {
//       "type": "MERCHANT_INITIATED_BILLING",
//       "merchant_preferences": {
//           "accepted_pymt_type": "INSTANT",
//           "multi_factor_activation": false,
//           "req_billing_address": false
//       }
//   },
//   "create_time": "2024-12-18T06:32:57.000Z",
//   "update_time": "2024-12-18T06:32:57.000Z",
//   "links": [
//       {
//           "href": "https://api-m.sandbox.paypal.com/v1/billing-agreements/agreements/B-7EV02238AC384892A/cancel",
//           "rel": "cancel",
//           "method": "POST"
//       },
//       {
//           "href": "https://api-m.sandbox.paypal.com/v1/billing-agreements/agreements/B-7EV02238AC384892A",
//           "rel": "self",
//           "method": "GET"
//       }
//   ]
// }

// B-7UF05345TB435943L
// B-05371422BA1928611
