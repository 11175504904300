import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';

const PAYMENTS_URL = 'payments/';

export const listPaymentMethods = async (token) => {
  try {
    const { data } = await axios.get(`${API_URL}${PAYMENTS_URL}methods/`, {
      headers: headers(token),
    });
    return data;
  } catch (error) {
    // TODO: manage errors
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const updateDefaultPaymentMethod = async (token, paymentMethodID) => {
  try {
    const { data } = await axios.patch(
      `${API_URL}${PAYMENTS_URL}methods/${paymentMethodID}/default/`,
      {},
      {
        headers: headers(token),
      },
    );
    return data;
  } catch (error) {
    // TODO: manage errors
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

// NEW VERSION
export const createBillingAgreement = async (token) => {
  try {
    const { data } = await axios.post(
      `${API_URL}${PAYMENTS_URL}billing-agreement/create/`,
      {},
      {
        headers: headers(token),
      },
    );
    return data;
  } catch (error) {
    // TODO: manage errors
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const executeBillingAgreement = async (token, billingToken) => {
  try {
    const { data } = await axios.post(
      `${API_URL}${PAYMENTS_URL}billing-agreement/execute/`,
      {
        billingToken,
      },
      {
        headers: headers(token),
      },
    );
    return data;
  } catch (error) {
    // TODO: manage errors
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const capturePayment = async (token) => {
  try {
    const { data } = await axios.post(
      `${API_URL}${PAYMENTS_URL}capture/`,
      {},
      {
        headers: headers(token),
      },
    );
    return data;
  } catch (error) {
    // TODO: manage errors
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
